<template>
  <div class="balance-cash-out">
    <!-- <div class="tab-header">
      <van-tabs v-model:active="active" @change="handleChange">
        <van-tab title="自购佣金"></van-tab>
        <van-tab title="粉丝佣金"></van-tab>
        <van-tab title="推荐佣金"></van-tab>
      </van-tabs>
    </div> -->
    <div v-if="active === 0" class="tab-content">
      <div class="t-box flex align-center">
        <img
          src="@/assets/img/user/balanceCashOut/zhifubao.png"
          alt=""
          class="left-img"
        />
        <p class="left-text">到账方式</p>
        <div class="right-text flex flex-right">
          <input
            v-model="cardNum"
            type="text"
            placeholder="添加支付宝提现账号"
            readonly
            @click="alertAlipay"
          />
        </div>
        <img
          src="@/assets/img/user/balanceCashOut/enter.png"
          alt=""
          class="right-img"
          @click="alertAlipay"
        />
      </div>
      <div class="btn-box">
        <p class="tip">提现金额（最小￥10，每日最高￥1000）</p>
        <div class="input-box flex">
          <p class="l-money">¥</p>
          <div class="input">
            <input
              v-model="tranAmt"
              type="text"
              maxlength="7"
              placeholder="0.00"
              oninput="this.value=this.value.replace(/[^0-9.]/g,'')"
            />
          </div>
          <p class="r-t">手续费¥0</p>
        </div>
        <div class="center-text flex">
          <p>可提现¥{{ (Number(userInfo.canBalance || 0) / 100).toFixed(2) }}</p>
          <p @click="cashAll">全部提现</p>
        </div>
        <p
          :class="[
            'btn',
            {
              'btn-sure': (this.tranAmt || this.tranAmt === 0) && this.cardNum,
            },
          ]"
          @click="cashFun"
        >
          提现
        </p>
        <p class="last-text" @click="lookCash">查看提现明细</p>
      </div>
    </div>
    <toast-box
      v-if="showToast"
      :show-toast="showToast"
      :msg="msg"
      :confirm-text="confirmText"
      @sure="handleSure"
    ></toast-box>
    <van-popup
      v-model:show="showPassword"
      position="center"
      :style="{ width: '100%' }"
      :close-on-click-overlay="false"
      class="user-balance-popup"
    >
      <div class="balance-box">
        <div class="close-img" @click="closeFun">
          <img src="@/assets/img/user/balanceCashOut/close.png" />
        </div>
        <div class="flex flex-center align-center balance-box-title">
          <span></span>请输入提现密码<span></span>
        </div>
        <div class="money flex align-center flex-center">
          <p class="left">提现</p>
          <p class="right">¥ {{ Number(tranAmt).toFixed(2) }}</p>
        </div>
        <div class="input-box flex flex-between">
          <input
            :value="valFun(1)"
            type="password"
            readonly
            @click="show = true"
          />
          <input
            :value="valFun(2)"
            type="password"
            readonly
            @click="show = true"
          />
          <input
            :value="valFun(3)"
            type="password"
            readonly
            @click="show = true"
          />
          <input
            :value="valFun(4)"
            type="password"
            readonly
            @click="show = true"
          />
          <input
            :value="valFun(5)"
            type="password"
            readonly
            @click="show = true"
          />
          <input
            :value="valFun(6)"
            type="password"
            readonly
            @click="show = true"
          />
        </div>
        <p class="placeholder">密码为6位数字</p>
        <p class="reset" @click="resetFun">重置提现密码</p>
      </div>
    </van-popup>
    <van-number-keyboard
      v-model="keyValue"
      :show="show"
      :maxlength="6"
      :extra-key="'00'"
      @blur="show = false"
    />
    <van-popup
      v-model:show="showAlipay"
      position="bottom"
      :style="{ width: '100%' }"
      :close-on-click-overlay="true"
      class="alipay-user-popup"
    >
      <div class="alipay-box">
        <p class="title">添加账号</p>
        <div class="s-li flex align-center">
          <p>支付宝账号</p>
          <input v-model="alipayAccount" type="text" placeholder="请输入账号" />
        </div>
        <div class="b-s flex flex-between">
          <p class="l" @click="showAlipay = false">取消</p>
          <p class="r" @click="handleConfirm">确认</p>
        </div>
      </div>
      <div class="line"></div>
    </van-popup>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.balance-cash-out {
  .tab-header {
    height: 52px;
    background: #ffffff;
    border-radius: 0px 0px 10px 10px;
    .van-tabs--line {
      width: 345px;
      margin: 0 auto;
    }
    .van-tabs__wrap {
      height: auto !important;
    }
    .van-tabs__nav {
      padding-top: 16px !important;
      padding-bottom: 15px !important;
      justify-content: space-between;
    }
    .van-tabs__line {
      width: 28px !important;
      height: 2px !important;
      background: #e62129 !important;
      bottom: 0 !important;
    }
    .van-tab {
      height: 21px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #999999 !important;
      line-height: 21px !important;
      padding: 0 !important;
      flex: unset !important;
      width: auto !important;
    }
    .van-tab:nth-child(1) {
      margin: 0 0 0 18px !important;
    }
    .van-tab:nth-child(3) {
      margin: 0 17px 0 0 !important;
    }
    .van-tab--active {
      color: #333333 !important;
    }
  }
  .user-balance-popup {
    width: 280px !important;
    top: 162px !important;
    transform: translateX(-50%) !important;
    background: none !important;
    overflow-y: unset !important;
    .balance-box {
      background: #ffffff;
      -webkit-border-radius: 8px;
      border-radius: 8px;
      position: relative;
      padding: 0 15px 20px;
      margin: 0 auto;
      position: relative;
      .close-img {
        width: 24px;
        height: 24px;
        position: absolute;
        top: -34px;
        right: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &-title {
        width: 100%;
        padding: 20px 0;
        color: #333;
        font-size: 15px;
        font-weight: 500;
        span {
          width: 10px;
          height: 1px;
          background-color: #e62129;
        }
        span:nth-child(1) {
          margin-right: 8px;
        }
        span:nth-child(2) {
          margin-left: 8px;
        }
      }
      .money {
        height: 22px;
        .left {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .right {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #e62129;
          margin-left: 5px;
        }
      }
      .input-box {
        width: 250px;
        margin: 15px auto 0;
        input {
          display: block;
          width: 38px;
          height: 38px;
          background: #ffffff;
          -webkit-border-radius: 2px;
          border-radius: 2px;
          border: 1px solid #d0d3d9;
          text-align: center;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 38px;
        }
      }
      .placeholder {
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        text-align: center;
        margin: 10px 0 25px;
      }
      .reset {
        width: 140px;
        height: 36px;
        border-radius: 18px;
        border: 1px solid #cccccc;
        line-height: 36px;
        text-align: center;
        margin: 0 auto;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8c8f90;
      }
    }
  }
  .van-number-keyboard {
    z-index: 20001;
  }
}
.alipay-user-popup {
  width: 100% !important;
  top: unset !important;
  bottom: 0 !important;
  transform: unset !important;
  background: #ffffff !important;
  -webkit-border-radius: 10px 10px 0px 0px;
  border-radius: 10px 10px 0px 0px;
  .line {
    position: absolute;
    left: 0;
    top: 53px;
    width: 100%;
    height: 1px;
    background: #eeeeee;
  }
  .alipay-box {
    width: 335px;
    height: 247px;
    background: #ffffff;
    padding: 15px 0 0;
    margin: 0 auto;
    position: relative;
    .title {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4a4a4a;
      line-height: 22px;
      text-align: center;
    }
    .s-li {
      margin-top: 38px;
      height: 20px;
      border-bottom: 1px solid #f6f6f6;
      padding-bottom: 15px;
      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: left;
        flex-shrink: 0;
      }
      input {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        text-align: right;
        flex-grow: 1;
      }
      input::-webkit-input-placeholder {
        color: #cccccc;
      }
    }
    .b-s {
      position: absolute;
      left: 0;
      bottom: 15px;
      width: 100%;
      padding-top: 15px;
      border-top: 1px solid #eeeeee;
      p {
        width: 160px;
        height: 40px;  
        -webkit-border-radius: 22px;
        border-radius: 22px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;  
        text-align: center;
        line-height: 40px;
      }
      .l {
        background: #f6f6f6;
        color: #999999;
      }
      .r {
        background: #e62129;
        color: #ffffff;
      }
    }
  }
}
</style>