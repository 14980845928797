import ToastBox from "../../../components/toast.vue";
import {
  checkMemberAuth,
  checkWithdrawPassword,
  bindZfbGet,
  bindZfbSave,
  wxWithdrawalPay,
  getWalletInfo
} from '@/utils/user.js';
export default {
  name: "BalanceCashOut",
  components: {
    ToastBox,
  },
  data() {
    return {
      alipayAccount: "",
      showAlipay: false,
      confirmText: "",
      tranAmt: "",
      showToast: false,
      msg: "",
      active: 0,
      showPassword: false,
      keyValue: "",
      show: false,
      userInfo: {},
      cardNum: "", // 支付宝账号
      isSet: true,
      path: "",
      realName: false,
      isTax: false
    }
  },
  watch: {
    keyValue: function (val) {
      if (val && val.length === 6) {
        this.lastFun()
      }
    }
  },
  created() {
    this.initData()
    this.getZfb()
  },
  methods: {
    alertAlipay() {
      this.showAlipay = true
      this.alipayAccount = ""
    },
    handleConfirm() {
      if (!this.alipayAccount) {
        return this.$toast.fail('请输入支付宝账号')
      }
      let data = {
        memberId: this.$cookies.get('memberId'),
        cardNum: this.alipayAccount
      };
      bindZfbSave(data).then(res => {
        if (res && res.code == "0") {
          this.showAlipay = false
          this.getZfb()
          setTimeout(() => {
            this.$toast("支付宝账号绑定成功")
          }, 200)
        }
      })
    },
    getZfb() {
      let data = {
        memberId: this.$cookies.get('memberId')
      };
      bindZfbGet(data).then(res => {
        if (res && res.code == "0") {
          this.cardNum = res.data && res.data.cardNum ? res.data.cardNum : ""
        }
      })
    },
    initData() {
      let data = {
        memberId: this.$cookies.get('memberId')
      };
      getWalletInfo(data).then(res => {
        if (res && res.code == "0") {
          this.userInfo = res.data || {}
        }
      })
      checkWithdrawPassword(data).then(res => {
        if (res.code == "0") {
          this.isSet = true
        } else if (res.code == "9905") {
          this.isSet = false
        } else {
          this.$toast.fail(res.message)
        }
      })
      checkMemberAuth(data).then(res => {
        if (res && res.code == "0") {
          this.isTax = res.data && res.data.confirmTax == 1 ? true : false
          this.realName = res.data && res.data.realName ? true : false
        }
      })
    },
    handleChange(val) {
      console.log(val)
    },
    valFun(num) {
      const len = this.keyValue.length
      if (len >= num) {
        const arr = this.keyValue.split('')
        return arr[num - 1]
      } else {
        return ""
      }
    },
    handleSure(val) {
      this.showToast = val
      if (this.path) {
        this.$router.push(this.path)
      }
    },
    handleToast(msg, text, bool, path) {
      this.msg = msg
      this.confirmText = text
      this.showToast = bool
      this.path = path
    },
    cashFun() {
      const canBalance = (Number(this.userInfo.canBalance || 0) / 100).toFixed(2)
      if (!this.tranAmt && this.tranAmt !== 0) {
        return
      } else if (Number(canBalance) === 0) {
        this.handleToast("您还没有可提现金额", "", true)
      } else if (canBalance < 0) {
        this.handleToast("可提现金额异常，不能提现", "", true)
      } else if (String(this.tranAmt) === '0') {
        this.handleToast("最小可提现金额10元", "", true)
      } else if (!/^[1-9]{1}\d*(.\d{1,2})?$|^0.\d{1,2}$/.test(this.tranAmt)) {
        this.handleToast("提现金额填写格式不正确", "", true)
      } else if (Number(this.tranAmt) < 10) {
        this.handleToast("最小可提现金额10元", "", true)
      } else if (Number(this.tranAmt) > canBalance) {
        this.handleToast("可提现金额最大" + canBalance + "元", "", true)
      } else if (Number(this.tranAmt) > 1000) {
        this.handleToast("每日最大可提现金额1000元", "", true)
      } else if (!this.isTax) {
        this.handleToast("提现前请先签署公示登记承诺书", "前往", true, "/taxCommit")
        return
      } else if (!this.realName) {
        this.handleToast("提现前请先实名认证", "前往", true, "/nameCertification")
        return
      } else if (!this.isSet) {
        this.handleToast("提现前请先设置提现密码", "前往", true, "/setCashPassword")
        return
      } else {
        this.showPassword = true
      }
    },
    lookCash() {
      this.$router.push("/cashRecord?active=2")
    },
    lastFun() {
      let data = {
        memberId: this.$cookies.get('memberId'),
        cardNum: this.cardNum,
        tranAmt: this.tranAmt * 100,
        payPwd: this.keyValue,
        fee: 0
      };
      wxWithdrawalPay(data).then(res => {
        this.closeFun()
        if (res && res.code == "0") {
          this.showToast = true
          this.msg = "已提交提现申请<br>会在3个工作日内处理提现申请";
          this.path = '/cashRecord?active=2'
        }
      })
    },
    closeFun() {
      this.keyValue = ""
      this.show = false
      this.showPassword = false
    },
    resetFun() {
      this.$router.push("/identityProof")
    },
    cashAll() {
      const canBalance = (Number(this.userInfo.canBalance || 0) / 100).toFixed(2)
      this.tranAmt = canBalance > 0 ? canBalance : ""
    }
  }
}